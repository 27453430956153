/// <reference path='../../node_modules/immutable/dist/immutable.d.ts' />
import { NgModule, ErrorHandler, LOCALE_ID } from '@angular/core';
import { HashLocationStrategy, LocationStrategy, registerLocaleData } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { APP_ROUTES } from './routes';
import { App } from './app';

import { TabService } from './components/layout/navbar/tabstrip/tabService';
import { ToastService } from '../framework/uniToast/toastService';

import { UniFrameworkModule } from '../framework/frameworkModule';
import { AuthService } from './authService';
import { FeaturePermissionService } from './featurePermissionService';
import { AuthGuard } from './authGuard';
import { UniAngularErrorHandler } from './angularErrorHandler';
import { InitParamsRouteGuard } from './initParamsRouteGuard';
import { UniQueryModule } from './components/uniquery/uniqueryModule';
import { LayoutModule } from './components/layout/layoutModule';
import { AppCommonModule } from './components/common/appCommonModule';
import { DashboardModule } from './components/dashboard/dashboardModule';
import { ReportsModule } from './components/reports/reportsModule';
import { InitModule } from './components/init/init.module';
import { UniTickerModule } from './components/uniticker/uniTickerModule';
import { CanDeactivateGuard } from './canDeactivateGuard';
import { RoutePermissionGuard } from './routePermissionGuard';
import { AboutModule } from './components/about/aboutModule';
import { SharingsModule } from './components/sharings/sharingsModule';

import { ReloadHelper } from './reload';
import { DateAdapter } from '@angular/material/core';
import { UniDateAdapter } from './date-adapter';

import localeNb from '@angular/common/locales/nb';
import { HeaderInterceptor } from './header-interceptor';
import { Logger } from '@uni-framework/core/logger';
import { InvoiceAccrualDefinitionService } from './services/common/InvoiceAccrualDefinitionService';
registerLocaleData(localeNb);

@NgModule({
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        RouterModule,

        UniFrameworkModule,

        APP_ROUTES,

        // COMMON MODULES
        LayoutModule.forRoot(),
        AppCommonModule,
        UniQueryModule,
        ReportsModule,
        InitModule,
        UniTickerModule,
        DashboardModule,
        SharingsModule,
        AboutModule,
    ],
    declarations: [App, ReloadHelper],
    bootstrap: [App],
    providers: [
        AuthService,
        FeaturePermissionService,
        AuthGuard,
        Logger,
        InvoiceAccrualDefinitionService,
        InitParamsRouteGuard,
        RoutePermissionGuard,
        CanDeactivateGuard,
        TabService,
        ToastService,
        { provide: LocationStrategy, useClass: HashLocationStrategy },
        { provide: ErrorHandler, useClass: UniAngularErrorHandler },
        { provide: DateAdapter, useClass: UniDateAdapter },
        { provide: HTTP_INTERCEPTORS, useClass: HeaderInterceptor, multi: true },
        { provide: LOCALE_ID, useValue: 'nb-NO' },
    ],
})
export class AppModule {}
