import { Injectable } from '@angular/core';
import { SalaryService } from './salary.service';
import { UniModalService } from '@uni-framework/uni-modal';
import { SalarySettingsWizardModal } from '@app/components/common/modals/salary-settings-wizard-modal/salary-settings-wizard-modal';
import { switchMap, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { Router } from '@angular/router';
import { AuthService } from '@app/authService';
import { AltinnAuthenticationService } from '../common/altinnAuthenticationService';

@Injectable({ providedIn: 'root' })
export class SalaryGuard {
    shouldCheckDashboardRedirect = true;

    constructor(
        private salaryService: SalaryService,
        private modalService: UniModalService,
        private authService: AuthService,
        private router: Router,
        private altinnAuthService: AltinnAuthenticationService,
    ) {}

    public canActivate() {
        this.altinnAuthService.testImplicitAuthentication();

        if (this.router.getCurrentNavigation()?.extras?.state?.bypassFormGuard) {
            return true;
        }

        return this.authService.authentication$.pipe(
            switchMap((isTemplateCompany) => {
                if (isTemplateCompany.activeCompany.IsTemplate) {
                    return of(true);
                } else {
                    return this.salaryService.hasValidSalarySettings().pipe(
                        switchMap((hasValidSettings: boolean) => {
                            if (!hasValidSettings) {
                                return this.modalService.open(SalarySettingsWizardModal).onClose.pipe(
                                    tap((canNavigate) => {
                                        if (canNavigate) {
                                            this.router.navigate(['salary', 'employees'], {
                                                state: { bypassFormGuard: true },
                                            });
                                        }
                                    }),
                                );
                            } else {
                                return of(true);
                            }
                        }),
                    );
                }
            }),
        );
    }
}
